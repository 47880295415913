import React from 'react';
import Layout from '../../layouts/contact/contact';
import Banner from '../../page-components/contact/banner/banner';

const Contact = () => {
  return (
    <Layout>
      <Banner
        who={'Our Sales Team'}
        messages={[
          'Understand user behavior in conversational interfaces',
          'Increase user engagement',
          'Track Key Performance Indicators',
        ]}
      />
    </Layout>
  );
};

export default Contact;
